<template>
  <div>
    <b-field class="file">
      <clipper-upload v-model="imgURL" :exif="false" accept="image/*">
        <b-button class="button is-primary" icon-left="upload" :loading="uploading" />
      </clipper-upload>
    </b-field>
  </div>
</template>

<script>
import alerts from "../common/alerts";
import ImageTypeAEnum from "../enums/imageType";
import ImageClipperDialog from "@/components/dialogs/ImageClipperDialog.vue";

export default {
  name: "FilePicker",
  props: {
    accept: {
      type: String,
      default: null
    },
    id: { type: Number, required: true },
    entityId: { type: Number, required: false },
    imageType: { type: Number, required: true }
  },
  data() {
    return {
      file: null,
      uploadPercent: 0,
      uploading: false,
      imgURL: null
    };
  },
  watch: {
    imgURL() {
      this.previewAndClip();
    }
  },
  methods: {
    previewAndClip() {
      this.$buefy.modal.open({
        parent: this,
        component: ImageClipperDialog,
        hasModalCard: true,
        props: { imgURL: this.imgURL },
        events: { newImage: this.upload }
      });
    },
    async upload(file) {
      this.uploading = true;
      const formData = new FormData();
      formData.append("dataImageBase64", file);
      formData.append("id", this.id);
      if (this.entityId) formData.append("entityId", this.entityId);

      await this.$http
        .post("imageupload/" + ImageTypeAEnum.ActionUrl[this.imageType], formData)
        .then(r => {
          this.$emit("new-image", r.data);
        })
        .catch(e => {
          alerts.showErrorAlert(e, "Error uploading file");
        })
        .finally(() => (this.uploading = false));
    }
  }
};
</script>
